/** Source: https://codepen.io/amit_sheen/pen/xxZeyjO */
.dotted-border {
    /* stylelint-disable-next-line max-line-length */
    background-image: repeating-linear-gradient(
        -2deg,
        #f3f3f330,
        #f3f3f330 11.28px,
        transparent 12px,
        transparent 25.16px,
        #f3f3f330 26px
    ),
        repeating-linear-gradient(
        88deg,
        #f3f3f330,
        #f3f3f330 11.28px,
        transparent 12px,
        transparent 25.16px,
        #f3f3f330 26px
    ),
        repeating-linear-gradient(
        178deg,
        #f3f3f330,
        #f3f3f330 11.28px,
        transparent 12px,
        transparent 25.16px,
        #f3f3f330 26px
    ),
        repeating-linear-gradient(
        268deg,
        #f3f3f330,
        #f3f3f330 11.28px,
        transparent 12px,
        transparent 25.16px,
        #f3f3f330 26px
    ) !important;
    background-size: 2px 100%, 100% 2px, 2px 100%, 100% 2px;
    background-position: 0 0, 0 0, 100% 0, 0 100%;
    background-repeat: no-repeat;
}
